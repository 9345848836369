export function useVideo() {

  const player = useState('videoPlayer', () => {
    return {
      x: 0,
      y: 0,
      time: 0,
      open: false,
      fixed: false,
      playing: false,
      videoId: '',
      title: ''
    }
  });

  const play = (videoId: string, title: string) => {
    player.value.x = 0;
    player.value.y = 0;
    player.value.time = 0;
    player.value.open = true;
    player.value.fixed = false;
    player.value.playing = true;
    player.value.videoId = videoId;
    player.value.title = title.replaceAll('&#039;', '\'');
  }

  return {
    play,
    player
  }

}